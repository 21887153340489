<template>
 <div>
    <v-row no-gutters class="pa-1">
        <v-col cols="12">
          <v-card v-if="!isOnline" flat class="text-center pa-4">
            
            <p style="margin-top: 120px;">
              <v-btn  x-large fab><v-icon>mdi-wifi-off</v-icon></v-btn>
            </p>
              Please connect to the Internet 
          </v-card>
          <v-card v-else class="pa-2">
            <v-toolbar dense flat class="mb-2">
               <div class="text-h5"> Bets</div>
                <v-spacer/>
                <span style="width: 140px; margin-top: 24px;"> 
                     <v-menu
                        v-model="from_menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field 
                              label="From Date"
                              :value="from_date"
                              readonly
                              filled
                              dense
                              outlined
                              append-icon="mdi-calendar"
                              v-on="on"
                            />
                        </template>
                        <v-date-picker v-model="from_date" no-title scrollable />
                    </v-menu>
               </span>
                 <span style="width: 140px; margin-top: 24px; margin-left: 7px;"> 
                     <v-menu
                        v-model="to_menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field 
                              label="Date"
                              :value="to_date"
                              readonly
                              filled
                              dense
                              outlined
                              append-icon="mdi-calendar"
                              v-on="on"
                            />
                        </template>
                        <v-date-picker v-model="to_date" no-title scrollable @input="to_menu=false, get_wallet_transaction(), search=''" />
                    </v-menu>
               </span>
               <span style="width: 140px; margin-left: 7px;">
                  <v-autocomplete
                  label="Filter"
                  dense
                  v-model="game_type"
                  hide-details
                  outlined
                  @change="search=''"
                  :items="ACTIVEGAMES(user.game_comm)"
                  />
               </span>
                 <span style="width: 220px; margin-left: 7px;">
                  <v-text-field
                      label="Enter acct. name"
                      v-model="search"
                      dense
                      outlined
                      hide-details
                    />
               </span>
              </v-toolbar>
                
                <div id="PrintReport">
                  <div>
                    Total: {{$money(TOTAL)}}<br/>
                    Date Covered: {{from_date}} - {{to_date}}<br/>
                    Filter: {{game_type}}<br/>
                    Search: {{search}}<br/>
                    Result(s): {{$commas(SEARCHTRANSACTIONS.length)}}
                  </div>
                  <table class="print_table md-font" >
                      <tr>
                          <th class="text-left">Acct. Name</th>
                          <th class="text-left">Game</th>
                          <th class="text-left">Trans. DT</th>
                          <th class="text-left">Draw DT</th>
                          <th class="text-right">AMOUNT</th>
                      </tr>
                      <tr v-for="(item, index) in SEARCHTRANSACTIONS" :key="index">
                          <td :class="'td-'+IS_ODD(index)">{{item.account_name.toUpperCase()}} </td>
                            <td :class="'td-'+IS_ODD(index)">{{GAMENAME(item.game)}}</td>
                          <td :class="'td-'+IS_ODD(index)">{{$moment(item.transaction_date).format("MMM DD, YYYY hh:mm a")}} </td>
                          <td :class="STATUS_COLOR(item.type) + ' td-' + IS_ODD(index) ">{{item.draw_date}}</td>
                          <td class="text-right" :class="'td-'+IS_ODD(index)">{{$money(item.amount)}}</td>
                      </tr>
                       <!-- <tr>
                          <th class="text-center lg-font" colspan="3">Tickets: {{$money(total)}} | Win: {{$money(wintotal)}}</th>
                      </tr> -->
                  </table>
                </div>
            </v-card>
        </v-col>
    </v-row>
</div>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    data: () => ({
      to_menu: false,
      from_menu: false,
        search:'',
        from_date:'',
        game_type: 'All Games',
        to_date: '',
        date_range:"",
        salesman:{},
        transactions:[],
        per_type:'Overall'
    }),
    created() {
      this.PING()
      // this.CHECK_BALANCE(this.user.id)
       this.from_date =   this.$moment().format("YYYY-MM-DD")//this.$moment().subtract(7, "days").format("YYYY-MM-DD")
        this.to_date =  this.$moment().format("YYYY-MM-DD")
        this.get_wallet_transaction()
        this.setDrawer(false)
    },
     mounted(){
       this.setLoggedIn(true)
        if (this.user == null) {
          this.$router.push('/')
        }else {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.user.token
          this.setOverlayLoading(false)
        }
    },
    computed:{
      isOnline(){
        return this.$store.state.is_online
      },
      user() {
        if(localStorage.user){
            return JSON.parse(localStorage.user)
        }
        return null
      },
      balance(){
        return  this.$store.state.balance.balance
      },
      SEARCHTRANSACTIONS(){
         var trans = []
        if(this.search == '') {
          trans = this.FILTERTRANSACTIONS
        } else {
           trans = this.FILTERTRANSACTIONS.reduce((res,item)=>{
              if(item.account_name.toLowerCase().includes(this.search.toLowerCase())) {
                res.push(item)
              }
              return res
           },[])
        }
           return trans
      },
      FILTERTRANSACTIONS(){
        var trans = []
        trans = this.transactions
        if(this.game_type != 'All Games') {
          trans = this.transactions.reduce((res,item)=>{
                if(item.game ==this.game_type) {
                  res.push(item)
                }
                return res
            }, [])
          }
          return trans
        //  if(this.game_type == 'LASTTWO') {
        //    trans = this.transactions.reduce((res,item)=>{
        //       if(item.game == "LASTTWO") {
        //         res.push(item)
        //       }
        //       return res
        //   }, [])
        //   return trans
        // } else if(this.game_type == '3D') {
        //     trans = this.transactions.reduce((res,item)=>{
        //       if(item.game == "3D") {
        //         res.push(item)
        //       }
        //       return res
        //     }, [])
        //     return trans
        // } else if(this.game_type == '4D') {
        //     trans = this.transactions.reduce((res,item)=>{
        //       if(item.game == "4D") {
        //         res.push(item)
        //       }
        //       return res
        //     }, [])
        //     return trans
        // } else if(this.game_type == 'PICK3') {
        //     trans = this.transactions.reduce((res,item)=>{
        //       if(item.game == "PICK3") {
        //         res.push(item)
        //       }
        //       return res
        //     }, [])
        //     return trans
        // } else {
        //   trans = this.transactions
        // }
        //  return trans
      },
      TOTAL(){
        var total = this.SEARCHTRANSACTIONS.reduce((res,item)=>{
            res+=this.$IsNum(item.amount)
            return res
        }, 0)
        return total
      },
       wintotal(){
        var total = this.transactions.reduce((res,item)=>{
            if(item.type == "Win") {
              res+=this.$IsNum(item.amount)
            }
            return res
        }, 0)
        return total
      },
    },
    methods: {
      ...mapMutations(['setAlert', 'setDrawer', 'setAlertColor', 'setAlertText', 'setLoggedIn', 'setOverlayLoading']),
      get_wallet_transaction(){
        this.transactions=[]
        this.setOverlayLoading(true)
        console.log( {req_by: this.user.id, from_date: this.from_date, to_date: this.to_date})
          this.$http.post("bets", {req_by: this.user.id, from_date: this.from_date, to_date: this.to_date, req_role:'gen_coordinator'}).then(response => {
           response.data.items != null?this.transactions =  response.data.items:this.transactions=[]
           console.log(response.data.items)
         this.setOverlayLoading(false)
         }).catch(e => {
           console.log(e.data)
         })
      }
    },
  }
</script>